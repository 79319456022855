import type {
  FeatureValueType,
  ThemeFragment,
  DesignLayoutType,
  EventDesignPreferenceRangeFragment,
  EventDesignPreferenceBooleanFragment,
  DesignLayoutFragment,
  EventDesignFragment,
  DesignFeatureType,
  FontFragment,
  Category
} from '@graphql/generated';

export type UserDesignPreferenceHandlers = {
  publishCurrentDraft: () => void;

  setBackgroundColor: (layoutType: DesignLayoutType, paletteId: string, backgroundColor: string, useSuggestedThemeColor: boolean) => void;
  setCustomCss: (customCss: string) => void;
  setGraphicAccent: (graphicAccentId: string) => void;
  setHeaderFont: (font: FontFragment) => void;
  setFontPack: (fontPack: EventDesignFragment['fonts']) => void;
  updateFontConfigs: (category: Category, font: NonNullable<NonNullable<EventDesignFragment['fonts']>[number]['font']>) => void;
  updateFontPackFontSize: (category: Category, fontSize: number) => void;
  setThemeAndLayout: (theme: ThemeFragment, layout: DesignLayoutFragment, options?: { useSuggestedThemeColor?: boolean; useSuggestedThemeTransparency?: boolean }) => void;
  setThemeWithCompatibleLayout: (theme: ThemeFragment) => void;
  setPreviewOption: (previewOption: PreviewOption) => void;
  setHasVisited: (isVisited: boolean) => void;
  setPresetTemplate: (
    font: FontFragment,
    theme: ThemeFragment,
    layout: DesignLayoutFragment,
    options?: { useSuggestedThemeColor?: boolean; useSuggestedThemeTransparency?: boolean }
  ) => void;
  updateUnpublishedChangesValue: (isUpToDate: boolean) => void;

  toggleBrannanInnerFrameBackgroundTransparency: (featureId: string, rangeValue: number) => void;
  toggleBrannanInnerFrameBorder: (featureId: string, booleanValue: boolean) => void;
  toggleUseSuggestedArtBackgroundColorAloha: (toggle: boolean) => void;
  toggleUseSuggestedArtBackgroundColorBrannan: (toggle: boolean) => void;
  toggleUseLightForegroundColor: (paletteId: string, shouldUseLightColor: boolean) => void;

  handleOnPreviewInitialized: () => void;
  setTabFavorite: (tab: WebsiteDesingerTabs) => void;
  setTabSearchTerm: (tab: WebsiteDesingerTabs, searchTerm?: string) => void;
  setFontPackSearchTerm: (searchTerm?: string) => void;
  setTabSelectedItem: (tab: WebsiteDesingerTabs, selectedItem?: DesingerSelectedItem) => void;
  toggleIsLocalStorageEnabled: (isLocalStorageEnabled: boolean) => void;
  toggleHasFilters: (tab: WebsiteDesingerTabs, hasFilters: boolean) => void;
  updateArtBackgroundColorConfigs: (payload: { useSuggestedArtBackgroundColorAloha: boolean; backgroundColor?: string }) => void;
};

export type EventDesignPreferences = EventDesignFragment['designPreferences'];
export type RangeDesignFeature = DesignFeatureType.brannanBackgroundTransparency;
export type BooleanDesignFeature = DesignFeatureType.brannanInnerFrameBorder;
export type ColorPointerDesignFeature = DesignFeatureType.alohaColorPreference | DesignFeatureType.brannanColorPreference;

// User Preferences

type UserFontPreference = {
  id: string;
  fontFamily: string;
};

export type UserDesignColorPalettePreferences = {
  backgroundColor: {
    paletteId: string;
    value: string;
  };
  foregroundColor: {
    paletteId: string;
    value: string;
  };
  useLightForegroundColor: boolean;
};

export type UserDesignPreferences = {
  eventDesignId: string;
  theme: {
    id: string;
    themeId: string;
  };
  themeId: string;
  accent: string;
  // layoutId: string;
  layout: {
    id: string;
    type: DesignLayoutType;
  };
  customCss: string;
  fonts: {
    header: UserFontPreference;
  };
  fontPacks: EventDesignFragment['fonts'];
  colorPalette: UserDesignColorPalettePreferences;
  useEventColorsForTheme: boolean;
  alohaPreferences: {
    useSuggestedArtBackgroundColor: boolean;
  };
  brannanPreferences: {
    useSuggestedArtBackgroundColor: boolean;
    innerFrameBackgroundTransparency: Pick<EventDesignPreferenceRangeFragment, 'id' | 'featureId' | 'rangeValue'> | null;
    innerFrameBorder: Pick<EventDesignPreferenceBooleanFragment, 'id' | 'featureId' | 'booleanValue'> | null;
  };
};

export type WebsiteLayoutFeature<T extends string, V extends FeatureValueType> = {
  type: T;
  display: string;
  supported: boolean;
  featureType: V;
};

export const WebsiteBrannanFeatures = {
  INNER_FRAME_BORDER: 'innerFrameBorder' as 'innerFrameBorder',
  INNER_FRAME_BACKGROUND_TRANSPARENCY: 'innerFrameBackgroundTransparency' as 'innerFrameBackgroundTransparency'
};

export type WebsiteBrannanFeatures = typeof WebsiteBrannanFeatures[keyof typeof WebsiteBrannanFeatures];

export type BrannanFeatureBackgroundTransparency = WebsiteLayoutFeature<typeof WebsiteBrannanFeatures['INNER_FRAME_BACKGROUND_TRANSPARENCY'], FeatureValueType.boolean>;
export type BrannanFeatureBorder = WebsiteLayoutFeature<typeof WebsiteBrannanFeatures['INNER_FRAME_BORDER'], FeatureValueType.boolean>;

// Bucket Organization

export type BucketFilter = {
  active: boolean;
  bucketId: string;
  count: number;
  display: string;
  itemIds: string[];
};

export type BucketFilterState = {
  filters: BucketFilter[];
};

// Misc

export const PreviewOption = {
  DESKTOP: 'desktop' as 'desktop',
  MOBILE: 'mobile' as 'mobile',
  STATIONERY: 'stationery' as 'stationery'
};

export type PreviewOption = typeof PreviewOption[keyof typeof PreviewOption];

export const PreviewDevice = {
  DESKTOP: PreviewOption.DESKTOP,
  MOBILE: PreviewOption.MOBILE,
  STATIONERY: PreviewOption.STATIONERY
};

export type PreviewDevice = typeof PreviewDevice[keyof typeof PreviewDevice];

export type PublishCTAContext = 'save' | 'saveAndOpen';

//

/**
 * TODO: Deprecate once we make the shift over to charm.
 */
export const LegacyPreviewTypes = {
  ACTION_FROM_USER_PREFERENCES: 'fromWebsiteDesignerPayload' as 'fromWebsiteDesignerPayload',
  ACTION_ON_INITIALIZED: 'previewInitialized' as 'previewInitialized',
  ACTION_SET_PREVIEW_TYPE: 'setPreviewType' as 'setPreviewType',
  SOURCE_JOY_PREVIEW: 'joyPreview' as 'joyPreview'
};

export type LegacyPreviewTypes = typeof LegacyPreviewTypes[keyof typeof LegacyPreviewTypes];

export enum PaperProviderEnum {
  paperlust = 'Paplust',
  paperculture = 'Paper Culture'
}

export enum WebsiteDesingerTabs {
  templates = 'templates',
  arts = 'arts',
  fonts = 'fonts'
}

export interface DesingerSelectedItem {
  id: string;
  isFavorite: boolean;
}
